(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/jupiter-fetch/assets/javascripts/jupiter-fetch.js') >= 0) return;  svs.modules.push('/components/core/jupiter-fetch/assets/javascripts/jupiter-fetch.js');
"use strict";


svs.core.jupiterFetch = svs.core.jupiterFetch || {};
svs.core.jupiterFetch.data = svs.core.jupiterFetch.data || {};
(function (_svs$core$data, _svs$core$data2, _svs$core$observabili) {
  const REQUEST_OPTIONS = ['method', 'headers', 'body', 'mode', 'credentials', 'redirect', 'referrer', 'integrity', 'signal', 'svsOptions'];
  const logger = new svs.core.log.Logger('component:core.jupiterFetch');
  const apiUrl = svs.core.jupiterFetch.data.url;
  let sessionTimeMs = (_svs$core$data = svs.core.data) === null || _svs$core$data === void 0 ? void 0 : _svs$core$data.sessionTimeMs;
  let realityCheckMsg = (_svs$core$data2 = svs.core.data) === null || _svs$core$data2 === void 0 ? void 0 : _svs$core$data2.rcmsg;
  const {
    provider,
    inject,
    SpanKind
  } = (_svs$core$observabili = svs.core.observability) !== null && _svs$core$observabili !== void 0 ? _svs$core$observabili : {};
  const tracer = provider === null || provider === void 0 ? void 0 : provider.getTracer();
  if (realityCheckMsg) {
    logger.debug("Found rcmsg from server with id: ".concat(realityCheckMsg.messageId));
    svs.events.publish('/componets/realitycheck/message', realityCheckMsg);
  }
  const getOption = async (option, resource, init) => {
    if (init[option]) {
      return init[option];
    }
    if (resource instanceof Request) {
      let ret;
      if (option === 'body') {
        ret = await resource.arrayBuffer();
        if (ret.byteLength === 0) {
          ret = undefined;
        }
      } else {
        ret = resource[option];
      }
      return ret;
    }
  };
  const extractOptions = async (resource, init) => {
    const ret = {};
    for (const option of REQUEST_OPTIONS) {
      ret[option] = await getOption(option, resource, init); 
    }
    return ret;
  };
  const validateOptions = options => {
    let contentType;
    if (options.headers instanceof Headers) {
      contentType = options.headers.get('Content-Type');
    } else {
      var _options$headers;
      contentType = (_options$headers = options.headers) === null || _options$headers === void 0 ? void 0 : _options$headers['Content-Type'];
    }
    if (contentType && !(contentType === 'application/json' || contentType === 'application/x-www-form-urlencoded')) {
      throw Error("Illegal Content-Type '".concat(contentType, "'. Content-Type must be one of 'application/json' or 'application/x-www-form-urlencoded'"));
    }
    switch (options.method) {
      case 'GET':
      case 'DELETE':
        return;
      case 'POST':
      case 'PUT':
        if (!contentType) {
          throw Error('Request is missing Content-Type Header');
        }
        return;
      default:
        throw Error("Unsupported method '".concat(options.method, "'"));
    }
  };
  const sendRpp = path => {
    const requestCount = decodeURIComponent(path).split('|').length;
    let isMultiFetch = false;
    if (path.indexOf('multifetch') > -1) {
      isMultiFetch = true;
    }
    svs.events.publish('/core/rpp', {
      requestCount,
      isMultiFetch
    });
  };
  const isUrl = (url, base) => {
    try {
      new URL(url, base);
      return true;
    } catch (e) {
      return false;
    }
  };
  const getUrl = function (resource) {
    let init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let url;
    if (init.url) {
      if (isUrl(init.url)) {
        url = init.url;
      } else if (isUrl(init.url, apiUrl)) {
        url = new URL(init.url, apiUrl);
      }
    } else if (!(resource instanceof Request)) {
      if (isUrl(resource)) {
        url = resource;
      } else if (isUrl(new URL(resource, apiUrl))) {
        url = new URL(resource, apiUrl);
      }
    } else {
      url = resource.url;
    }
    if (url) {
      return url.toString();
    }
    throw Error('No url provided in request');
  };

  svs.core.jupiterFetch.url = () => apiUrl;

  svs.core.jupiterFetch.get = path => {
    const url = "".concat(apiUrl, "/").concat(path);
    const headers = new Headers({
      accept: 'application/json'
    });
    return svs.core.jupiterFetch.fetch(url, {
      method: 'GET',
      headers
    });
  };

  svs.core.jupiterFetch.post = (path, data) => {
    const url = "".concat(apiUrl, "/").concat(path);
    const body = JSON.stringify(data);
    const headers = new Headers({
      accept: 'application/json',
      'Content-Type': 'application/json'
    });
    return svs.core.jupiterFetch.fetch(url, {
      method: 'POST',
      headers,
      body
    });
  };

  svs.core.jupiterFetch.multiFetch = function (paths) {
    let serial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const separator = encodeURIComponent('|');
    let urls;
    if (Array.isArray(paths)) {
      urls = paths.join(separator);
    } else if (paths instanceof Map) {
      urls = Array.from(paths.values()).join(separator);
    } else {
      throw Error('paths must be of type Array or Map');
    }
    const url = "".concat(apiUrl, "/multifetch?serial=").concat(serial, "&urls=").concat(urls);
    const headers = new Headers({
      accept: 'application/json'
    });
    return svs.core.jupiterFetch.fetch(url, {
      method: 'GET',
      headers
    });
  };

  svs.core.jupiterFetch.fetch = async function (resource) {
    var _options$svsOptions;
    let init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const options = await extractOptions(resource, init);
    validateOptions(options);
    const controller = new AbortController();
    const signal = controller.signal;
    if (options.signal) {
      options.signal.onabort = () => {
        controller.abort();
      };
    }
    options.signal = signal; 
    options.cache = 'no-store';
    options.credentials = 'include';
    const disableTelemetry = Boolean((_options$svsOptions = options.svsOptions) === null || _options$svsOptions === void 0 ? void 0 : _options$svsOptions.disableTelemetry);
    delete options.svsOptions;
    const url = getUrl(resource, init);
    const spanMethod = (init.method || 'GET').toUpperCase();
    const span = tracer === null || tracer === void 0 ? void 0 : tracer.startSpan(spanMethod, {
      kind: SpanKind.CLIENT
    });
    span === null || span === void 0 || span.setAttribute('http.method', spanMethod);
    span === null || span === void 0 || span.setAttribute('http.url', url);
    span === null || span === void 0 || span.setAttribute('http.host', url.split('/')[0]);
    const traceHeaders = inject ? inject({}, span) : {};
    if (!options.headers) {
      options.headers = disableTelemetry ? {} : traceHeaders;
    } else if (options.headers instanceof Headers) {
      options.headers.append('traceparent', traceHeaders.traceparent);
    } else {
      options.headers.traceparent = traceHeaders.traceparent;
    }
    if (disableTelemetry) {
      delete options.headers.traceparent;
    }
    sendRpp(url);
    let response;
    try {
      const timeout = setTimeout(() => controller.abort(), svs.core.config.data.svsconfig.clientAjaxTimeout);
      response = await fetch(url, options);
      svs.events.publish('/core/jupiter/response', response.status);
      clearTimeout(timeout);
    } catch (e) {
      if (e.code === 20) {
        const msg = 'Unable to call Jupiter. Request timed out or was aborted';
        span === null || span === void 0 || span.setAttribute('error', true);
        span === null || span === void 0 || span.setAttribute('http.status_code', 0);
        span === null || span === void 0 || span.addEvent(msg);
        span === null || span === void 0 || span.end();
        throw Error(msg);
      } else {
        const msg = "Unable to call Jupiter. ".concat(e.toString());
        span === null || span === void 0 || span.setAttribute('error', true);
        span === null || span === void 0 || span.setAttribute('http.status_code', 0);
        span === null || span === void 0 || span.addEvent(msg);
        span === null || span === void 0 || span.end();
        throw e;
      }
    }
    const ret = response.clone(); 
    let body;
    span === null || span === void 0 || span.setAttribute('http.status_code', response.status);
    span === null || span === void 0 || span.end();
    try {
      body = await response.json();
    } catch (e) {
    }
    if (response.ok) {
      logger.debug("Jupiter call success '".concat(options.method, " ").concat(options.url, "' finished with status ").concat(response.status));
      if (!body) {
        throw Error('Jupiter responded ok status but with a body that couldn\'t be decoded as JSON');
      }
      if (body.responses) {
        var _body$responses$;
        sessionTimeMs = (_body$responses$ = body.responses[0]) === null || _body$responses$ === void 0 || (_body$responses$ = _body$responses$.requestInfo) === null || _body$responses$ === void 0 ? void 0 : _body$responses$.sessionTimeMs;
      } else {
        var _body$requestInfo;
        sessionTimeMs = (_body$requestInfo = body.requestInfo) === null || _body$requestInfo === void 0 ? void 0 : _body$requestInfo.sessionTimeMs;
      }
      if (sessionTimeMs) {
        svs.events.publish('/core/jupiter/sessionTimeMs', sessionTimeMs);
      }
      const rcMessageId = response.headers.get('X-RC-Messageid');
      const rcPayload = response.headers.get('X-RC-Payload');
      if (rcMessageId || rcPayload) {
        logger.debug("Received rcmsg with id: ".concat(rcMessageId));
        realityCheckMsg = {
          messageId: rcMessageId,
          payload: rcPayload
        };
        svs.events.publish('/componets/realitycheck/message', realityCheckMsg);
      }
    }
    return ret;
  };
})();

 })(window);