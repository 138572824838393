(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/jupiter-fetch/assets/javascripts/parse-error-response.js') >= 0) return;  svs.modules.push('/components/core/jupiter-fetch/assets/javascripts/parse-error-response.js');
"use strict";


svs.core = svs.core || {};
svs.core.jupiterFetch = svs.core.jupiterFetch || {};
svs.core.jupiterFetch.parseErrorResponse = response => {
  let params;
  if (response && response.error) {
    if (response.error) {
      if (response.error.code) {
        params = {};
        params.code = response.error.code;
      }
      if (response.error.requestId) {
        params = params || {};
        params.reqId = response.error.requestId;
      }
      if (response.error.message) {
        params = params || {};
        params.message = decodeURI(response.error.message);
      }
    }
  } else if (response && response.status) {
    if (response.status.code) {
      params = {};
      params.code = response.status.code;
    }
    if (response.status.description) {
      params = params || {};
      params.description = response.status.description;
    }
    if (response.message) {
      params = params || {};
      params.message = response.message;
    }
  }
  return params;
};

 })(window);